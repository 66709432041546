import React, { FC } from "react";
import styles from "./header.module.css";
import { RouterLink } from "mobx-state-router";
import { RouterLinkProps } from "mobx-state-router/dist/types/components/router-link";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import {UserRouteNames} from "src/routes";

export const RouterNavLink: FC<RouterLinkProps> = (props) => {
    const { className, activeClassName, ...pass } = props;
    return (
        <RouterLink
            className={styles.header__headLink + " " + className}
            activeClassName={styles.header__headLink_active}
            {...pass}
        />
    );
};

export const HeaderLink = ({ name, text }: { name: string; text: string }) => (
    <RouterNavLink routeName={name}>{text}</RouterNavLink>
);

type HeaderHeadProps = {
    HeaderNav?: React.ReactNode;
    UserWidget?: React.ReactNode;
};

const HeaderHead: FC<HeaderHeadProps> = () => {
    const {
        rootStore: { loginStore, userStore: store },
    } = useRootStore();
    
    return useObserver(() => {
        return (
            <div
                className={
                    styles.header__head + " d-flex justify-content-between align-items-center "
                }
            >
                <div className={"text-light relative"}>
                    <span className={'mr-3'}>{ store.profile?.email }</span>
                    <span><a href="/swagger" className={`${styles.User__swagger} mr-3`} target="_blank">Документация API (Swagger)</a></span>
                    <span>
                        <RouterLink routeName={UserRouteNames.settingsPage} className={`${styles.User__swagger}`}>Настройки</RouterLink>
                    </span>
                    <span className={`absolute ${styles.User__balance}`}>Баланc: { store.profile?.balance } &#8381;</span>
                </div>
                <div className={styles.header__headUser}>
                    <div className="d-flex justify-content-end">
                        <div className="d-flex flex-column justify-content-center">
                            <button className={styles.User__subtitle} onClick={() => loginStore.LogOut()}>
                                Выйти из системы
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
};


export const Header: FC = () => {
    return useObserver(() => (
        <div className={styles.header__back}>
            <div className={styles.header__maxWidth + " pb-0"}>
                <HeaderHead />
            </div>
        </div>
    ));
};
