import React from "react";
import createBrowserHistory from "history/createBrowserHistory";
import "react-datepicker/dist/react-datepicker.css";
import "./global.css";
import "./globalBS.css";
import "tailwindcss/tailwind.css"
import { observer, Provider } from "mobx-react";
import { RootStore } from "src/stores/RootStore";
import { HistoryAdapter } from "mobx-state-router";
import { UserShell } from "src/components/UserShell/UserShell";
import "mobx-react-lite/batchingForReactDom";

let root: RootStore;

const ensureInitialized = () => {
    if (root) return;
    root = new RootStore();
    const historyAdapter = new HistoryAdapter(root.routerStore, createBrowserHistory());
    historyAdapter.observeRouterStateChanges();
};

export const App = observer(() => {
    ensureInitialized();
    return (
        <Provider rootStore={root}>
           <UserShell /> 
        </Provider>
    );
});
