export enum CheckStatus {
        Pending = "Pending",
        Approved = "Approved",
        Rejected = "Rejected",
        Error = "Error",
}

export enum InfoResultRowType {
        Approve = "Approve",
        Neither = "Neither",
        Forbid = "Forbid",
        Error = "Error",
}

export interface InfoResultRow {
        type : InfoResultRowType;
        description : string;
}

export interface CheckRequestDto {
        error : string;
        id : string;
        creationDate : string;
        status : CheckStatus;
        name : string;
        reasons : InfoResultRow[];
        cost : number;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface ErrorCode {
        code : string;
        description : string;
}

export interface Result {
        success : boolean;
        error : ErrorCode;
}

export interface UserRoleDto {
        id : number;
        userId : number;
        role : string;
}

export interface ResultT<T> {
        value : T;
        success : boolean;
        error : ErrorCode;
}

export interface UserProfileDto {
        id : number;
        email : string;
        name : string;
        avatarImage : number;
        balance : number;
}

export interface UserSettingsDto {
        callbackUrl : string;
        callbackActive : boolean;
}


export interface ICoreRpcProxyForCheckRequestRpc {
    getRequest (id : string) : Promise<CheckRequestDto>;
    getRequests (skip : number, take : number) : Promise<ListResult<CheckRequestDto>>;
    deleteRequest (id : string) : Promise<Result>;
}
export class CoreRpcProxyForCheckRequestRpc implements ICoreRpcProxyForCheckRequestRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getRequest (id : string) : Promise<CheckRequestDto>    {
        return this.parent.send<CheckRequestDto>({Target: 'CheckRequestRpc', MethodSignature: 'CkdldFJlcXVlc3QLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getRequests (skip : number, take : number) : Promise<ListResult<CheckRequestDto>>    {
        return this.parent.send<ListResult<CheckRequestDto>>({Target: 'CheckRequestRpc', MethodSignature: 'C0dldFJlcXVlc3RzDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [skip,take] });
    }
    public deleteRequest (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'CheckRequestRpc', MethodSignature: 'DURlbGV0ZVJlcXVlc3QLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
}
export interface ICoreRpcProxyForRolesRpc {
    getMyRoles () : Promise<string[]>;
    getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>;
}
export class CoreRpcProxyForRolesRpc implements ICoreRpcProxyForRolesRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getMyRoles () : Promise<string[]>    {
        return this.parent.send<string[]>({Target: 'RolesRpc', MethodSignature: 'CkdldE15Um9sZXM=', Arguments: [] });
    }
    public getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>    {
        return this.parent.send<UserRoleDto[]>({Target: 'RolesRpc', MethodSignature: 'EUdldFVzZXJSb2xlc0J5SWRzjAFTeXN0ZW0uQ29sbGVjdGlvbnMuR2VuZXJpYy5MaXN0YDFbW1N5c3RlbS5JbnQ2NCwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj01LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [ids] });
    }
}
export interface ICoreRpcProxyForUserLoginRpc {
    register (email : string, password : string) : Promise<Result>;
    confirmAccount (code : string) : Promise<Result>;
    azureAuthUrl () : Promise<string>;
    azureLogoutUrl () : Promise<string>;
    azureReloginUrl () : Promise<string>;
    login (username : string, password : string) : Promise<ResultT<string>>;
    loginAdmin (username : string, password : string) : Promise<ResultT<string>>;
    loginViaAzureCode (code : string) : Promise<ResultT<string>>;
    checkAuthToken () : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public register (email : string, password : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'CFJlZ2lzdGVyDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [email,password] });
    }
    public confirmAccount (code : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'DkNvbmZpcm1BY2NvdW50DVN5c3RlbS5TdHJpbmc=', Arguments: [code] });
    }
    public azureAuthUrl () : Promise<string>    {
        return this.parent.send<string>({Target: 'UserLoginRpc', MethodSignature: 'DEF6dXJlQXV0aFVybA==', Arguments: [] });
    }
    public azureLogoutUrl () : Promise<string>    {
        return this.parent.send<string>({Target: 'UserLoginRpc', MethodSignature: 'DkF6dXJlTG9nb3V0VXJs', Arguments: [] });
    }
    public azureReloginUrl () : Promise<string>    {
        return this.parent.send<string>({Target: 'UserLoginRpc', MethodSignature: 'D0F6dXJlUmVsb2dpblVybA==', Arguments: [] });
    }
    public login (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'BUxvZ2luDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [username,password] });
    }
    public loginAdmin (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'CkxvZ2luQWRtaW4NU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [username,password] });
    }
    public loginViaAzureCode (code : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'EUxvZ2luVmlhQXp1cmVDb2RlDVN5c3RlbS5TdHJpbmc=', Arguments: [code] });
    }
    public checkAuthToken () : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DkNoZWNrQXV0aFRva2Vu', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserProfileRpc {
    getProfile () : Promise<UserProfileDto>;
    updateProfile (profile : UserProfileDto) : Promise<Result>;
    uploadUserPhoto (data : number[]) : Promise<Result>;
    removeUserPhoto () : Promise<Result>;
}
export class CoreRpcProxyForUserProfileRpc implements ICoreRpcProxyForUserProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getProfile () : Promise<UserProfileDto>    {
        return this.parent.send<UserProfileDto>({Target: 'UserProfileRpc', MethodSignature: 'CkdldFByb2ZpbGU=', Arguments: [] });
    }
    public updateProfile (profile : UserProfileDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'DVVwZGF0ZVByb2ZpbGUkQ3JlZGl0LlRydXN0Q2hlY2suRHRvLlVzZXJQcm9maWxlRHRv', Arguments: [profile] });
    }
    public uploadUserPhoto (data : number[]) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1VwbG9hZFVzZXJQaG90b4sBU3lzdGVtLkNvbGxlY3Rpb25zLkdlbmVyaWMuTGlzdGAxW1tTeXN0ZW0uQnl0ZSwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj01LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [data] });
    }
    public removeUserPhoto () : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1JlbW92ZVVzZXJQaG90bw==', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserSettingsRpc {
    getUserSettings () : Promise<UserSettingsDto>;
    updateUserSettings (model : UserSettingsDto) : Promise<Result>;
}
export class CoreRpcProxyForUserSettingsRpc implements ICoreRpcProxyForUserSettingsRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getUserSettings () : Promise<UserSettingsDto>    {
        return this.parent.send<UserSettingsDto>({Target: 'UserSettingsRpc', MethodSignature: 'D0dldFVzZXJTZXR0aW5ncw==', Arguments: [] });
    }
    public updateUserSettings (model : UserSettingsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserSettingsRpc', MethodSignature: 'ElVwZGF0ZVVzZXJTZXR0aW5ncyVDcmVkaXQuVHJ1c3RDaGVjay5EdG8uVXNlclNldHRpbmdzRHRv', Arguments: [model] });
    }
}
export interface ICoreApi {
    checkRequest : ICoreRpcProxyForCheckRequestRpc;
    roles : ICoreRpcProxyForRolesRpc;
    userLogin : ICoreRpcProxyForUserLoginRpc;
    userProfile : ICoreRpcProxyForUserProfileRpc;
    userSettings : ICoreRpcProxyForUserSettingsRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.checkRequest = new CoreRpcProxyForCheckRequestRpc(this);
    this.roles = new CoreRpcProxyForRolesRpc(this);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.userProfile = new CoreRpcProxyForUserProfileRpc(this);
    this.userSettings = new CoreRpcProxyForUserSettingsRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    checkRequest : CoreRpcProxyForCheckRequestRpc;
    roles : CoreRpcProxyForRolesRpc;
    userLogin : CoreRpcProxyForUserLoginRpc;
    userProfile : CoreRpcProxyForUserProfileRpc;
    userSettings : CoreRpcProxyForUserSettingsRpc;
}
