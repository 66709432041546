import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { UserViewMap } from "src/routes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { Header } from "src/components/Header/Header";

export const UserShell = () => {
    const {
        rootStore: { routerStore, userRpc },
    } = useRootStore();

    return useObserver(() => (
        <>
            {userRpc.isAuthorized  && <Header />}
            <SuspensePlaceholder>
                <RouterView routerStore={routerStore} viewMap={UserViewMap} />
            </SuspensePlaceholder>
        </>
    ));
};
