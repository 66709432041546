import { RootStore } from "src/stores/RootStore";
import { CheckRequestDto } from "src/api";
import { observable } from "mobx";

export class UserDetailPageStore {
    @observable request?: CheckRequestDto;

    constructor(private readonly root: RootStore) {}

    async load(id: string): Promise<void> {
        this.request = await this.root.userRpc.checkRequest.getRequest(id.toString());
    }
}
