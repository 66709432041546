import { action, observable, runInAction } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { UserSettingsDto } from "src/api";

export class UserSettingsStore {
    @observable isLoading: boolean = false;
    @observable errors: boolean = false;
    @observable profileSettings?: UserSettingsDto;
    @observable profileCallbackUrl?: string;
    @observable profileCallbackUrlActive: boolean;

    constructor(private readonly root: RootStore) {
        this.profileCallbackUrl = "";
        this.profileCallbackUrlActive = false;
    }

    @action async loadProfileSetting() {
        this.errors = false;
        const res = await this.root.userRpc.userSettings.getUserSettings();
        runInAction(() => {
            this.profileSettings = res;
            this.profileCallbackUrl = res.callbackUrl;
            this.profileCallbackUrlActive = res.callbackActive;
        });
    }

     validURL(string: string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    @action async updateSettingCallback() {
        this.isLoading = true;
        this.errors = !this.validURL(this.profileCallbackUrl ? this.profileCallbackUrl : '');
        if (this.profileCallbackUrl && !this.errors) {
            const update = await this.root.userRpc.userSettings.updateUserSettings({
                callbackUrl: this.profileCallbackUrl,
                callbackActive: this.profileCallbackUrlActive
            });
            if (update.success) {
                await this.loadProfileSetting();
                alert('Callback Успешно сохранен')
            } else {
                alert('Что-то пошло не так, попробуйте еще раз')
            }
        }
    }
}
