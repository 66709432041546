import { RootStore } from "src/stores/RootStore";
import { ReactTableStore } from "src/stores/table/ReactTableStore";
import { CheckRequestDto } from "src/api";
import { action, observable } from "mobx";
import formatISO from "date-fns/formatISO";

const getBlobFromResponse = async (res: Response) => {
    const blob = await res.blob();
    const disposition = res.headers.get("content-disposition");
    const matches = /"([^"]*)"/.exec(disposition ?? "");
    const filename = matches != null && matches[1] ? matches[1] : "report.xlsx";
    return { blob, filename };
};
const downloadFileFromResponse = async (res: Response) => {
    const link = document.createElement("a");
    const { blob, filename } = await getBlobFromResponse(res);
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

class UserReportWidgetStore {
    @observable root;
    @observable lockButton = false;
    @observable fromYear = new Date().getFullYear();
    @observable fromMonth = 0;
    @observable toYear = new Date().getFullYear();
    @observable toMonth = new Date().getMonth();

    constructor(root: RootStore) {
        this.root = root;
    }

    @action async loadReport() {
        this.lockButton = true;
        const data = {
            From: formatISO(new Date(this.fromYear, this.fromMonth, 1, 0, 0, 0)),
            To: formatISO(new Date(this.toYear, this.toMonth + 1, 0, 23, 59, 59)),
        };

        const res = await this.root.userRpc.authorizedAjax("/reports", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            this.lockButton = false;
            return;
        }

        this.lockButton = false;
        await downloadFileFromResponse(res);
    }
}

export class UserMasterPageStore extends ReactTableStore<CheckRequestDto> {
    @observable userReportWidgetStore;
    @observable root;

    constructor(root: RootStore) {
        super();
        this.root = root;
        this.userReportWidgetStore = new UserReportWidgetStore(root);
    }

    async refresh(): Promise<void> {
        const response = await this.root.userRpc.checkRequest.getRequests(this.skip, this.take);
        this.fillItems(response.items, response.totalCount);
    }
}
