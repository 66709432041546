import React from "react";
import { Route, RouterState } from "mobx-state-router";
import { UserAuthorizedOnlyHook, UserRouteToRootIfAuthorizedHook } from "src/routing/hooks";
import { convertRoutes } from "src/routing/route";
import { AccountConfirmPage, LoginPage, MasterPage, DetailPage, SettingsPage } from "./routing/LazyRoutes";

export enum UserRouteNames {
    login = "user-login",
    confirm = "user-confirm",
    masterPage = "user-master-page",
    detailPage = "user-detail-page",
    settingsPage = "user-setting-page",
}

export const UserViewMap = {
    [UserRouteNames.login]: <LoginPage />,
    [UserRouteNames.masterPage]: <MasterPage />,
    [UserRouteNames.detailPage]: <DetailPage />,
    [UserRouteNames.confirm]: <AccountConfirmPage />,
    [UserRouteNames.settingsPage]: <SettingsPage />,
};

const UserRoutes: Route[] = convertRoutes([
    {
        pattern: "/",
        name: UserRouteNames.masterPage,
        hooks: [UserAuthorizedOnlyHook],
        onEnter: async (root) => {
            await root.userMasterPageStore.load();
            await root.userStore.loadProfile();
        },
    },
    {
        pattern: "/login",
        name: UserRouteNames.login,
        hooks: [UserRouteToRootIfAuthorizedHook],
    },
    {
        pattern: "/confirm",
        name: UserRouteNames.confirm,
        hooks: [UserAuthorizedOnlyHook],
        onEnter: async (root, to) => {
            if (await root.userConfirmAccountStore.confirmAccount(to.queryParams["code"]))
                throw new RouterState(UserRouteNames.login);
        },
    },
    {
        pattern: "/settings",
        name: UserRouteNames.settingsPage,
        hooks: [UserAuthorizedOnlyHook],
        onEnter: async (root) => {
            await root.userStore.loadProfile();
            await root.userSettingsStore.loadProfileSetting();
        },
    },
    {
        pattern: "/request/:id",
        name: UserRouteNames.detailPage,
        hooks: [UserAuthorizedOnlyHook],
        onEnter: async (root, to) => {
            await root.userStore.loadProfile();
            await root.userDetailPageStore.load(to.params.id);
        },
    },
]);

export const Routes: Route[] = UserRoutes;
