import { action, observable, runInAction } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { UserProfileDto } from "src/api";

export class UserProfileStore {
    @observable profile?: UserProfileDto;
    @observable profileBalance?: any;

    constructor(private readonly root: RootStore) {}
    
    async loadProfile() {
        const res = await this.root.userRpc.userProfile.getProfile();
        
        runInAction(() => {
            this.profile = res;
        });
    }
}
