import { action, computed, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { UserLoginStore } from "src/stores/user/UserLoginStore";
import { CoreApi } from "src/api";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { Routes } from "src/routes";
import { PreviewBoxStore } from "src/components/PreviewBox/PreviewBoxStore";
import { UserConfirmAccountStore } from "src/stores/user/UserConfirmAccountStore";
import { UserMasterPageStore } from "src/stores/user/UserMasterPageStore";
import { UserDetailPageStore } from "src/stores/user/UserDetailPageStore";
import { UserSettingsStore } from "src/stores/user/UserSettingsStore";

type AuthHeaderKeys = "X-User-Auth" | "X-Admin-Auth" | "X-Jury-Auth";

const apiUrl = "/tsrpc";

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null;
    private localStorageKey;

    @computed get isAuthorized() {
        return this.token != null;
    }

    constructor(path: string, authHeaderKey: AuthHeaderKeys) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = {};
            if (this.token) request.headers[authHeaderKey] = this.token;
            const res = await fetch(url, request);
            if (res.status == 401) {
                window.location.reload();
                this.resetUserToken();
                await new Promise(() => {
                    // Never
                });
            }
            return res;
        });
        const localStorageKey = "auth-token:" + authHeaderKey;
        this.localStorageKey = localStorageKey;
        this.token = window.localStorage.getItem(localStorageKey);
    }

    @action authorizedAjax(request: RequestInfo, init?: RequestInit) {
        const maybeInit = init ?? {};
        if (!maybeInit.headers) maybeInit.headers = {};
        maybeInit.headers = { ...maybeInit.headers, Authorization: `Bearer ${this.token}` };
        return fetch(request, maybeInit);
    }

    @action setUserToken(token: string) {
        this.token = token;
        window.localStorage.setItem(this.localStorageKey, token);
    }

    @action resetUserToken() {
        this.token = null;
        window.localStorage.removeItem(this.localStorageKey);
    }
}

export class RootStore {
    @observable userRpc = new SecureCoreApi(apiUrl, "X-User-Auth");
    @observable anonRpc = new CoreApi(apiUrl);
    @observable routerStore = new RouterStore(this, Routes, new RouterState("not-found"));
    @observable previewStore = new PreviewBoxStore();

    @observable loginStore = new UserLoginStore(this);
    @observable userStore = new UserProfileStore(this);
    @observable userConfirmAccountStore = new UserConfirmAccountStore(this);
    @observable userMasterPageStore = new UserMasterPageStore(this);
    @observable userDetailPageStore = new UserDetailPageStore(this);
    @observable userSettingsStore = new UserSettingsStore(this);
}
